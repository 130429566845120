import React from 'react';

// Utils
import {toIndexPageNHref} from '../utils';
import {usePreHeaderBgSx, useSubFooterBgSx} from '../hooks';

// Components
import {Base} from '../../ui/layouts';
import {FlexCol, Grid} from '../../ui/components';
import {PageContextProvider} from '../contexts';
import {ArticleCard, ArticlePagination, Footer, Head, Header, PreHeader, SubFooter} from '../components';

const SPACING = ['md', 'md', 'md', 'lg'];
const CONTENT_MAX_WIDTH = ['sm', 'sm', '3xl', '3xl', '6xl'];

// Markup
const IndexPageN = (props) => {
    const {articles} = props.pageContext;

    const preHeaderBgSx = usePreHeaderBgSx();
    const subFooterBgSx = useSubFooterBgSx();

    return (
        <PageContextProvider value={props.pageContext}>
            <Head
                meta={{
                    title: `Artikel - Seite ${articles.pageInfo.currentPage}`,
                    description: 'Evans Blog-Artikel im Überblick',
                    canonical: toIndexPageNHref(articles.pageInfo.currentPage),
                    prev: articles.pageInfo.hasPreviousPage
                        ? toIndexPageNHref(articles.pageInfo.currentPage - 1)
                        : null,
                    next: articles.pageInfo.hasNextPage
                        ? toIndexPageNHref(articles.pageInfo.currentPage + 1)
                        : null
                }}
            />
            <Base.Container>
                <Base.PreHeader sx={preHeaderBgSx}>
                    <PreHeader/>
                </Base.PreHeader>
                <Base.Header>
                    <Header/>
                </Base.Header>
                <Base.Body
                    px={['sm']}
                    py={SPACING}
                >
                    <FlexCol
                        width="100%"
                        maxWidth={CONTENT_MAX_WIDTH}
                        space={SPACING}
                        alignX="stretch"
                        alignY="top"
                        sx={{
                            alignSelf: 'stretch'
                        }}
                    >
                        <ArticlePagination
                            title={`Artikel - Seite ${articles.pageInfo.currentPage}`}
                            pageInfo={articles.pageInfo}
                        />
                        <Grid
                            columns={[1, 1, 2, 2, 3]}
                            space={SPACING}
                            alignX="center"
                            rowReverse={true}
                            wrapChildren={true}
                        >
                            {articles.items.map(article => (
                                <ArticleCard key={article.page.key} article={article}/>
                            ))}
                        </Grid>
                        <ArticlePagination
                            pageInfo={articles.pageInfo}
                        />
                    </FlexCol>
                </Base.Body>
                <Base.Footer>
                    <Footer/>
                </Base.Footer>
                <Base.SubFooter sx={subFooterBgSx}>
                    <SubFooter/>
                </Base.SubFooter>
            </Base.Container>
        </PageContextProvider>
    );
};

export default IndexPageN;

